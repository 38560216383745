import { WebIM } from '@/im/WebIM'
import store from '@/store'
import * as types from '@/store/mutation-types'
import { ConferenceType } from '@/utils/constant'

export class VideoChat {
  fromUsername = ''
  store = null
  WebIM = null
  storeModuleName = 'video-chat'

  constructor(fromUsername) {
    this.WebIM = WebIM
    this.store = store
    this.fromUsername = fromUsername
    // 挂载监听视频聊天的相关回调
    this.initEmedia()
  }

  isWebRtc() {
    return this.WebIM.EMedia.isWebRTC
  }

  login() {
    return new Promise(resolve => {
      this.WebIM.conn.open({
        apiUrl: this.WebIM.config.apiURL,
        user: this.fromUsername,
        pwd: this.fromUsername,
        appKey: this.WebIM.config.appkey,
        success: (res) => {
          console.log('login success', res)
          if (res) {
            // 视频聊天需要设置token，否则环信认证失败
            this.setIdentity(`${this.WebIM.config.appkey}_${res.user && res.user.username}`, res.access_token)
          }
          this.store.commit(types.getMutationType(types.SET_LOGIN_STATUS), true)
          resolve(res)
        }
      })
    })
  }

  // 退出环信
  logout() {
    this.WebIM.conn.close()
  }

  setUsername(username) {
    this.fromUsername = username
  }

  setIdentity(name, token) {
    this.WebIM.EMedia.mgr.setIdentity(name, token)
  }

  /**
   * 创建多人视频会议
   * @param videoTag video标签
   * @param password 会议密码 string 默认为空字符串
   * @param confrType 会议类型 10:普通会议模式、11:大会议模式、12:直播模式。number 必需
   * @param options
   *   rec 是否开启通话录制 boolean 非必需
   *   recMerge 是否开启通话录制合并 boolean 非必需
   *   supportWechatMiniProgram 会议是否支持小程序端 boolean 非必需， 默认不支持
   *   password 会议密码 string 默认为空字符串
   *   confrType 会议类型 10:普通会议模式、11:大会议模式、12:直播模式。number 必需， 默认10
   *   maxVideoCount 最大会议人数， 默认9
   * @returns {Promise<any>}
   *  返回值：{
        error: 0,
        confrId: "LBJ13H9WJJEVJTGL1U1PIQ00C2", // 会议id
        password: "xxxx", // 创建会议时设置的密码
        role: 7, // 在会议中的角色 这里因为是创建者，所以是 7: 管理员
        roleToken:"eyJ0eXAiOiJKV1QiLCJhbG *** f1gg_QJWxhs-jqmuFok", //创建者的token
        type: 10 // 会议类型
      }
   */
  async createConference(videoTag, options = {}) {
    const {
      res = true, recMerge = true, supportWechatMiniProgram = false, maxVideoCount = 9, confrType = ConferenceType.NORMAL, password = ''
    } = options
    // 创建会议
    const result = await this.WebIM.EMedia.mgr.createConference({
      confrType,
      password,
      res,
      recMerge,
      supportWechatMiniProgram,
      maxVideoCount
    })

    // 提交store
    this.store.commit(types.getMutationType(types.SET_CONFERENCE_CONFIG, this.storeModuleName), result)
    // 加入会议
    await this.joinConference(result.confrId, videoTag, result.password)

    return result
  }

  /**
   * 加入视频会议
   * @param confrId 创建会议成功后返回的 confrId
   * @param videoTag 视频标签 必传
   * @param pwd 创建会议成功后返回的 password, 默认为空字符串
   * @param ext 发布流的扩展信息 Object 非必需。会议其他成员可接收到
   * @returns {Promise<any>}
   * 返回值： {
       confrId: "LBJ13H9WJJEVJTGL1U1PIQ00C7", // 会议id
       joinId: "LBJ13H9WJJEVJTGL1U1PIQ00C7M9", //创建会议时设置的密码
       password: "0.010568535799199363", // 会议成员在会议中的身份id（唯一）
       role, // 在会议中的角色 创建者加入会议永远是 7: 管理员，其他人加入返回的是 3: 主播
       roleToken: "eyJ0eXAiOiJK *** FaXuMVlqPOTofRRdE", // 加入会议者的token
       type: 10 // 会议类型
     }
   */
  async joinConference(confrId, videoTag, pwd = '', ext = {}) {
    const result = await this.WebIM.EMedia.mgr.joinConference(confrId, pwd, ext)
    // 推送视频流
    // await this.publish(videoTag)
    const pushedStream = await this.publish(videoTag)
    // 回流到video标签显示
    await this.streamBindVideo(pushedStream, videoTag)

    return result
  }

  /**
   * 加入会议之后 发布流  video或audio属性 至少存在一个
   * @param videoTag 视频标签
   * @param video 是否发布视频
   * @param audio 是否发布音频
   * @param ext 发布流的扩展信息 Object 非必需。会议其他成员可接收到
   * @returns {Promise<any>}
   */
  publish(videoTag, video = true, audio = true, ext = {}) {
    // 每回发起前重置状态
    this.store.commit(types.getMutationType(types.SET_MEDIA_ERROR, this.storeModuleName), false)
    return new Promise((resolve) => {
      this.WebIM.EMedia.mgr.publish({
        audio,
        video
      }, videoTag, ext).then(res => {
        resolve(res)
      }).catch(err => {
        console.log('publish stream fail: ', err)
        if (err.error === -201) {
          this.store.commit(types.getMutationType(types.SET_MEDIA_ERROR, this.storeModuleName), true)
        }
      })
    })
  }

  unPublish(stream) {
    this.WebIM.EMedia.mgr.unpublish(stream)
  }

  // 流发布成功后，在video标签显示流
  streamBindVideo(stream, videoElement) {
    this.WebIM.EMedia.mgr.streamBindVideo(stream, videoElement)
  }

  getConferenceInfo(confrId, password = '') {
    return this.WebIM.EMedia.mgr.selectConfr(confrId, password)
  }

  // 退出会议
  exitConference() {
    this.WebIM.EMedia.mgr.exitConference()
  }

  // 管理员销毁会议
  destroyConference(confrId) {
    this.WebIM.EMedia.mgr.destroyConference(confrId)
  }

  // 监听流变化
  // videoElement： video 标签
  onMediaChanged(videoElement) {
    return new Promise(resolve => {
      this.WebIM.EMedia.mgr.onMediaChanaged(videoElement, (constaints) => {
        resolve(constaints)
      })
    })
  }

  /**
   * 订阅视频流
   * @param member 发布流人员的信息，必需。也就是 onStreamAdded 方法的 member
   * @param stream 流信息，必需。也就是 onStreamAdded 方法的 stream
   * @param video  需要显示流的 video 标签，必需
   * @param subVideo 是否订阅视频，必需
   * @param subAudio 是否订阅音频，必需
   */
  subscribe(member, stream, video, subVideo = true, subAudio = true) {
    this.WebIM.EMedia.mgr.subscribe(member, stream, subVideo, subAudio, video)
  }

  unSubscribe(stream) {
    this.WebIM.EMedia.mgr.unsubscribe(stream)
  }

  // 切换前后摄像头，返回promise
  // confrId 会议ID
  switchCamera(confrId) {
    return this.WebIM.EMedia.mgr.switchMobileCamera(confrId)
  }

  /**
   * 开启视频
   * @param ownStream 自己的视频流
   * @returns {Promise<any>}
   */
  resumeVideo(ownStream) {
    return this.WebIM.EMedia.mgr.resumeVideo(ownStream)
  }

  /**
   * 关闭视频
   * @param ownStream 自己的视频流
   * @returns {Promise<any>}
   */
  pauseVideo(ownStream) {
    return this.WebIM.EMedia.mgr.pauseVideo(ownStream)
  }

  /**
   * 开启音频
   * @param ownStream 自己的视频流
   * @returns {Promise<any>}
   */
  resumeAudio(ownStream) {
    return this.WebIM.EMedia.mgr.resumeAudio(ownStream)
  }

  /**
   * 关闭音频
   * @param ownStream 自己的视频流
   * @returns {Promise<any>}
   */
  pauseAudio(ownStream) {
    return this.WebIM.EMedia.mgr.pauseAudio(ownStream)
  }

  // 注册监听回调
  initEmedia() {
    this.onConferenceExit()
    this.onMemberExited()
    this.onMemberJoined()
    this.onRoleChanged()
    this.onAdminChanged()
    this.onNetworkDisconnect()
    this.onNetworkWeak()
  }

  // 会议退出；自己主动退 或 服务端主动关闭；
  onConferenceExit() {
    this.WebIM.EMedia.mgr.onConferenceExit = (reason, failed) => {
      reason = reason || 0
      switch (reason) {
        case 0:
          reason = '正常挂断'
          break
        case 1:
          reason = '没响应'
          break
        case 2:
          reason = '服务器拒绝'
          break
        case 3:
          reason = '对方忙'
          break
        case 4:
          reason = '失败,可能是网络或服务器拒绝'
          if (failed === -9527) {
            reason = '失败,网络原因'
          }
          if (failed === -500) {
            reason = 'Ticket失效'
          }
          if (failed === -502) {
            reason = 'Ticket过期'
          }
          if (failed === -504) {
            reason = '链接已失效'
          }
          if (failed === -508) {
            reason = '会议无效'
          }
          break
        case 5:
          reason = '不支持'
          break
        case 10:
          reason = '其他设备登录'
          break
        case 11:
          reason = '会议关闭'
          break
        default:
          break
      }
      console.log('Hangup reason ' + (reason || 0))
    }
  }

  // 有人加入会议
  onMemberJoined() {
    // member: 加入会议成员信息
    this.WebIM.EMedia.mgr.onMemberJoined = (member) => {
      console.log(member.name + ' 加入群聊.')
      this.store.commit(types.getMutationType(types.SET_MEMBER_LIST, this.storeModuleName), { name: member.name, exit: false })
    }
  }

  // 有人退出会议
  onMemberExited() {
    // member: 退出会议成员信息
    this.WebIM.EMedia.mgr.onMemberExited = (member, reason) => {
      console.log(member.name + ' 退出群聊.', reason)
      this.store.commit(types.getMutationType(types.SET_MEMBER_LIST, this.storeModuleName), { name: member.name, exit: true })
    }
  }

  // 自己角色变更
  onRoleChanged() {
    // role: 变更后的角色
    this.WebIM.EMedia.mgr.onRoleChanged = (role) => {
      console.log('onRoleChanged', role)
    }
  }

  // 有媒体流添， 需要在页面中注册监听并传入回调（自己发布的流也会触发 stream.located() == true ）
  onStreamAdded(callback) {
    // member: 发布流的成员信息，stream：流信息
    this.WebIM.EMedia.mgr.onStreamAdded = (member, stream) => {
      console.log('onStreamAdded member', member)
      console.log('onStreamAdded stream', stream)
      // 流变化，执行回调显示到video标签中，通过store的方式，后进的人，多个流会遗漏，且stream会一直改变，无法深拷贝
      if (typeof callback === 'function') {
        callback(member, stream)
      }
    }
  }

  // 有媒体流移除，需要在页面中注册监听并传入回调
  onStreamRemoved(callback) {
    // member: 移除流的成员信息，stream：流信息
    this.WebIM.EMedia.mgr.onStreamRemoved = (member, stream) => {
      console.log('onStreamRemoved', member, stream)
      // 流变化，执行回调显示到video标签中，通过store的方式，后进的人，多个流会遗漏，且stream会一直改变，无法深拷贝
      if (typeof callback === 'function') {
        callback(member, stream)
      }
    }
  }

  // 管理员变更
  onAdminChanged() {
    // admin 管理员信息
    this.WebIM.EMedia.mgr.onAdminChanged = admin => {
      console.log('onAdminChanged', admin)
    }
  }

  // 监听弱网状态
  onNetworkWeak() {
    // streamId 会议中的流 ID
    this.WebIM.EMedia.mgr.onNetworkWeak = streamId => {
      console.log('网络不佳：', streamId)
      this.store.commit(types.getMutationType(types.SET_WEAK_NETWORK, this.storeModuleName), true)
    }
  }

  // 监听断网状态
  onNetworkDisconnect() {
    // streamId 会议中的流 ID
    this.WebIM.EMedia.mgr.onNetworkDisconnect = streamId => {
      console.log('断网：', streamId)
      this.store.commit(types.getMutationType(types.SET_WEAK_NETWORK, this.storeModuleName), true)
    }
  }
}

// 初始化视频聊天 fromUsername:环信账号，用于登录
export function initVideoChat(fromUsername) {
  return new VideoChat(fromUsername)
}
