<template>
  <div class="video-chat">
    <div class="self-video">
      <video ref="self" class="video" autoplay playsinline muted />
    </div>
    <div class="opposite-video">
      <video ref="opposite" class="video" autoplay playsinline muted />
    </div>
    <div v-if="result" class="button">
      <van-button type="danger" round @click="onExit">
        挂断
      </van-button>
    </div>
    <div v-if="maskStaus" class="mask">
      <img src="../../assets/img/meg.png" class="mask-img">
    </div>
  </div>
</template>

<script>
import videoChatMixin from '../../components/chat/mixins/video-chat'
export default {
  mixins:[videoChatMixin],
  data() {
    return {
      result: null,
      maskStaus:false,
      self: {
        nickName: "",
        streamId: "",
        openVideo: false,
        openAudio: false,
        video: '<div className="default"></div>'
      },
      opposite: {
        nickName: "",
        streamId: "",
        openVideo: false,
        video: '<div className="default"></div>'
      }
    }
  },

  watch: {
    noMediaDevice(newVal) {
      if (newVal) {
        console.log("您的设备不支持视频聊天，请检查摄像头或者麦克风是否正常")
      }
    }
  },
  methods: {
    //返回关闭会议
    onExit() {
      this.videoChatModel.exitConference()
      this.$router.back()
    },
    handleRemoveStream(member, stream) {
      this.removeVideoTag(member, stream)
    },
    removeVideoTag() {},
    async joinConference(confrId) {
        try {
          this.result = await this.videoChatModel.joinConference(confrId, this.$refs.self)
        } catch (error) {
          console.log('join conference fail: ', error)
          this.$toast('视频不存在！')
        }
      },
    // 处理自己发布的流
    handlePublishStream(member, stream) {
      console.log('先处理')
      const video = this.$refs.self
      this.self = {
        nickName: member.name,
        stream: stream,
        localStreamId: stream.id,
        openVideo: true,
        openAudio: true
      }
      this.videoChatModel.onMediaChanged(video).then((res) => {
        this.self = {
          nickName: member.name,
          stream: stream,
          localStreamId: stream.id,
          openVideo: res.video,
          openAudio: res.audio
        }
      })
      this.videoChatModel.streamBindVideo(stream, video)
    },
    // 处理接受到的流
    handleReceiveStream(member, stream) {
      const video = this.$refs.opposite
      this.opposite = {
        nickName: member.name,
        streamId: stream.id,
        openVideo: true,
        openAudio: true,
        video
      }

      this.videoChatModel.onMediaChanged(video).then((res) => {
        this.opposite = {
          nickName: member.name,
          streamId: stream.id,
          openVideo: res.video,
          openAudio: res.audio,
          video
        }
      })
      this.videoChatModel.streamBindVideo(stream, video)
      this.videoChatModel.subscribe(member, stream, video)
    },
      async _initConference() {
        const { confrId } = this.$route.params
        // 先判断是否有单据id，查询最新的会议ID，存在会议ID加入会议， 否则创建会议
      if (confrId) {
            await this.joinConference(confrId)
          } else {
           this.$toast('请等待医生发起视频问诊')
          }
      }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/styles/variable.less";
.video-chat {
  width: 100%;
  height: 100%;

  .self-video {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 240px;
    height: 320px;
    z-index:@z-index-highest;
  }
  .opposite-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:@z-index-higher;
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .button {
    position: absolute;
    bottom: 160px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: @z-index-highest;

    .van-button--danger {
      width: 180px;
    }
  }
  .mask{
    position:fixed;
    top:90px;
    bottom: 0;
    left:0;
    right:0;
    background: #000;
    opacity: .9;
    .mask-img{
      width:468px;
      height: 289px;
      position: absolute;
      right:50px;
    }
  }
}
</style>
