/*
 * @Author: your name
 * @Date: 2021-03-15 10:57:45
 * @LastEditTime: 2021-03-15 11:29:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\chat\mixins\video-chat.js
 */
import {mapGetters, mapMutations} from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import {initVideoChat} from '@/im/video-chat'

export default {
  computed: {
    ...mapGetters({
      isWeakNetwork: 'video-chat/isWeakNetwork',
      noMediaDevice: 'video-chat/noMediaDevice'
      // username: 'phoneNum'
    })
  },
  watch: {
    noMediaDevice(newVal) {
      if (newVal) {
        this.$message.warning('您的设备不支持视频聊天，请检查摄像头或者麦克风是否正常')
      }
    }
    // isWeakNetwork(newVal) {
    //   if (newVal) {
    //     this.$message.warning('当前网络不佳')
    //     // 提示后改变标识，否则后续网络不佳时无法再触发
    //     this.setWeakNetworkStatus(false)
    //   }
    // }
  },
  async mounted() {
    var ua = window.navigator.userAgent.toLowerCase()
    let u = navigator.userAgent
    let isiOS =!!u.match(/(i[^;]+;)( U;)? CPU.+Mac OS X/) //ios终端
    if (ua.match(/MicroMessenger/i) == 'micromessenger'&& isiOS) {
      // 微信浏览器
      this.maskStaus=true
    } else {
      // 其他浏览器
      this.maskStaus=false
      this.username = this.$route.query.username
      this._initChatModel()
      await this._initVideoChat()
    }


    // if (this.videoChatModel.isWebRtc()) {
    //   await this._initVideoChat()
    // } else {
    //   this.$toast('您的浏览器不支持视频聊天功能，请下载谷歌浏览器最新版本使用')
    // }
  },
  methods: {
    onExit() {
      this.videoChatModel.exitConference()
      this.$router.back()
    },
    handlePublishStream() {
      // console.log('处理')
    },
    onStreamChange(member, stream) {
      if (stream.located()) {
        this.handlePublishStream(member, stream)
      } else {
        this.handleReceiveStream(member, stream)
      }
    },
    handleReceiveStream() {
    },
    handleRemoveStream(member, stream) {
      this.removeVideoTag(member, stream)
    },
    removeVideoTag() {
    },
    _initChatModel() {
      if (!this.videoChatModel) {
        this.videoChatModel = initVideoChat(this.username)
      }
      this.videoChatModel.onStreamAdded(this.onStreamChange)
      this.videoChatModel.onStreamRemoved(this.handleRemoveStream)
    },
    _initVideoChat() {
      // 确保每回登录环信都触发success回调，登录状态再登录环信不会触发回调
      try {
        this.videoChatModel.logout()
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
      setTimeout(async () => {
        // 确保每回都重新登录环信
        await this.videoChatModel.login()
        await this._initConference()
      }, 300)
    },
    ...mapMutations({
      setWeakNetworkStatus: mutationTypes.getMutationType(mutationTypes.SET_WEAK_NETWORK, 'video-chat')
    })
  }
}
